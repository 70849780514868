.candidateDetailLabel {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 0;
}
.candidateArchiveName {
  display: flex;
  text-align: center;
}
.candidateArchive-Name {
  width: 100%;
  font-size: 24px;
  font-weight: 500;
}
.candidateDetailsImage {
  width: 150px;
}
.candidateTableAction {
  text-align: right;
}
.socialIcon {
}
.sideBarWrapper {
  align-items: center;
}

.candidateArchive-emptyPage {
  font-size: 20px;
  font-weight: 500;
  color: #ccc;
  width: 80%;
  text-align: center;
  min-height: 50%;
  padding-top: 100px;
  padding-bottom: 50px;
}

.candidateArchive-candidateStatus-container {
  font-weight: 500;
}

.candidateArchive-candidateStatus-container button {
  padding: 0px;
}

.candidateArchive-candidateStatus-text {
  padding-bottom: 12px;
}

.candidateArchive-candidateStatus-text .partner {
  font-weight: 400;
  font-size: 14px;
}
.candidateArchive-candidateStatus-text .statusExpirationDate {
  font-weight: 400;
  font-size: 12px;
}

.candidateArchive-candidateStatus-text.orange {
  color: orange;
}

.candidateArchive-candidateStatus-text.red {
  color: red;
}

.candidateArchive-candidateStatus-text.green {
  color: green;
}

.candidateArchive_brand-logo-container .brand-logo {
  padding-right: 16px;
  padding-bottom: 16px;
}
